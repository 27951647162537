import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import InnerPage from "../components/innerPage";
import useSWR from "swr";
import {graphqlFetcher} from "../modules/swrFetchers";
import LoadingIndicator from "../components/loadingIndicator";
import ErrorBlock from "../components/errorBlock";
import parse from "html-react-parser";

/**
 * donation page
 * @returns {JSX.Element}
 * @private
 */
const Donation = () => {
    const {t} = useTranslation();
    const {language, languages, originalPath} = useI18next();


    const [pageContent, setPageContent] = useState();
    const [isError, setIsError] = useState(false);
    const pageGraphQL = `
    query GetPostsEdges($categoryName: String!) {
      posts(where: {categoryName: $categoryName}) {
        edges {
          node {
            content(format: RENDERED)
          }
        }
      }
    }
    `
    const pageGraphQLVar = {
        categoryName: `donation-${language}`,
    }
    const { data, error } = useSWR([pageGraphQL, pageGraphQLVar], graphqlFetcher)

    const breadcrumbs = [
        {
            title: t('donation'),
        }
    ]


    useEffect(() => {
        if (data?.posts?.edges[0]?.node?.content) {
            setPageContent(data.posts.edges[0].node.content)
            setIsError(false);
        }
        else {
            if (data) {
                setIsError(true)
            }
        }

    }, [data])

    return (
        <Layout>
            <SEO title={t('donation')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('donation')}
            >
                {!data && !error && (
                    <>
                        <LoadingIndicator />
                    </>
                )}

                {(error || isError) && (
                    <>
                        <ErrorBlock />
                    </>
                )}

                {pageContent && (
                    <>
                        {parse(pageContent)}
                    </>
                )}

                {/*<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">*/}
                {/*    <input type="hidden" name="cmd" value="_s-xclick"/>*/}
                {/*        <input type="hidden" name="hosted_button_id" value="VCBN8UY543ZNA"/>*/}
                {/*            <table>*/}
                {/*                <tr>*/}
                {/*                    <td><input type="hidden" name="on0" value="MONTHLY DONATION 每月固定捐款"/>MONTHLY DONATION*/}
                {/*                        每月固定捐款</td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <td><select name="os0">*/}
                {/*                        <option value="AMOUNT">AMOUNT : $100.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT.">AMOUNT. : $200.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT..">AMOUNT.. : $300.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT...">AMOUNT... : $400.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT....">AMOUNT.... : $500.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT.....">AMOUNT..... : $1,000.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT......">AMOUNT...... : $2,000.00 HKD - 每月</option>*/}
                {/*                        <option value="AMOUNT.......">AMOUNT....... : $5,000.00 HKD - 每月</option>*/}
                {/*                    </select></td>*/}
                {/*                </tr>*/}
                {/*            </table>*/}
                {/*            <input type="hidden" name="currency_code" value="HKD"/>*/}
                {/*                <input type="image"*/}
                {/*                       src="https://www.paypalobjects.com/zh_HK/HK/i/btn/btn_subscribeCC_LG.gif"*/}
                {/*                       border="0" name="submit" alt="PayPal － 更安全、更簡單的網上付款方式！"/>*/}
                {/*                    <img alt="" border="0" src="https://www.paypalobjects.com/zh_HK/i/scr/pixel.gif"*/}
                {/*                         width="1" height="1"/>*/}
                {/*</form>*/}
                {/*<br/>*/}
                {/*<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">*/}
                {/*    <input type="hidden" name="cmd" value="_s-xclick"/>*/}
                {/*        <input type="hidden" name="hosted_button_id" value="FHG5BXGUABHV8"/>*/}
                {/*            <table>*/}
                {/*                <tr>*/}
                {/*                    <td>*/}
                {/*                        一次性自訂捐款*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <td><input type="hidden" name="on0" value="最低捐款 Min. Donation HKD10.00"/><p*/}
                {/*                        className="style5">最低捐款 Min. Donation HKD10.00</p></td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <td><input type="text" name="os0" maxLength="200"/></td>*/}
                {/*                </tr>*/}
                {/*            </table>*/}
                {/*            <input type="image"*/}
                {/*                   src="https://www.paypalobjects.com/zh_HK/HK/i/btn/btn_buynowCC_LG_wCUP.gif"*/}
                {/*                   border="0" name="submit" alt="PayPal － 更安全、更簡單的網上付款方式！"/>*/}
                {/*                <img alt="" border="0" src="https://www.paypalobjects.com/zh_HK/i/scr/pixel.gif"*/}
                {/*                     width="1" height="1"/>*/}
                {/*</form>*/}
            </InnerPage>
        </Layout>
    );
};

export default Donation;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;